html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	min-height: 100vh;
	background-position: center;
	background-image: url("src/FKBackground.png");
	font-family: 'Roboto', sans-serif;
}

.app-header {
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
}

.main-wrapper {
	height: 100%;
}

.positronx {
	text-decoration: none;
	color: #ffffff;
}

.box {
	position: relative;
	top: 0;
	opacity: 1;
	float: left;
	padding: 60px 50px 40px 50px;
	width: 100%;
	background: #fff;
	border-radius: 10px;
	transform: scale(1);
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	z-index: 5;
	max-width: 330px;
}

.box.back {
	transform: scale(.95);
	-webkit-transform: scale(.95);
	-ms-transform: scale(.95);
	top: -20px;
	opacity: .8;
	z-index: -1;
}

.box:before {
	content: "";
	width: 100%;
	height: 30px;
	border-radius: 10px;
	position: absolute;
	top: -10px;
	background: rgba(255, 255, 255, .6);
	left: 0;
	transform: scale(.95);
	-webkit-transform: scale(.95);
	-ms-transform: scale(.95);
	z-index: -1;
}

.main-wrapper .example-form {
	min-width: 100%;
	max-width: 300px;
	width: 100%;
}

.main-wrapper .example-full-width,
.main-wrapper .btn-block {
	width: 100%;
}

.main-wrapper mat-card-header {
	text-align: center;
	width: 100%;
	display: block;
	font-weight: 700;
}

.main-wrapper mat-card-header mat-card-title {
	font-size: 30px;
	margin: 0;
}

.main-wrapper .mat-card {
	padding: 45px 70px 55px;
}

.main-wrapper .mat-stroked-button {
	border: 1px solid currentColor;
	line-height: 54px;
	background: #FFF7FA;
}

.main-wrapper .mat-form-field-appearance-legacy .mat-form-field-infix {
	padding: 0.8375em 0;
}
